import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keepLoggedIn, setKeepLoggedIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {
        // Implement login logic here
        console.log('Logging in with:', { email, password, keepLoggedIn });
    };

    return (
        <div className="flex  items-center justify-center px-4 bg-white dark:bg-slate-950">
            <div className="flex flex-col justify-center p-6 w-full md:w-1/2 lg:w-1/3 bg-white dark:bg-slate-950 rounded-lg relative z-10  border-purple-200 dark:border-cyan-500 ">
                <div className="flex items-center justify-center mb-6">
                    {/* <a href="/" className="header-logo h-16 w-auto text-center">
                        
                    </a> */}
                </div>
                <h2 className="mb-5 text-2xl sm:text-3xl md:text-4xl font-bold uppercase tracking-normal bg-clip-text text-transparent bg-gradient-to-r from-purple-700 to-cyan-500 text-center">
                    Sign in to your account
                </h2>
                <p className="mb-4 text-gray-600 dark:text-gray-300 text-center">
                    Don't have an account yet? <a href="/signup" className="text-purple-700 text-1xl font-semibold hover:underline">Create now</a>
                </p>
                <form className="space-y-6">
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-envelope text-gray-400"></i>
                            </div>
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email to get started"
                                className="p-inputtext w-full pl-10 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-purple-200 rounded-md bg-gray-50 focus:outline-none focus:border-cyan-500 focus:bg-white caret-slate-600"
                                required
                            />
                        </div>
                    </div>
                    <div className="space-y-5">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <i className="pi pi-lock text-gray-400"></i>
                            </div>
                            <InputText
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                className="p-inputtext w-full pl-10 pr-10 text-black placeholder-gray-500 transition-all duration-200 border border-purple-200 rounded-md bg-gray-50 focus:outline-none focus:border-cyan-500 focus:bg-white caret-slate-600"
                                required
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={() => setShowPassword(!showPassword)}>
                                <i className={`pi ${showPassword ? 'pi-eye-slash' : 'pi-eye'} text-gray-400`}></i>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">


                            <a href="/" className="text-sm font-medium text-purple-700 transition-all duration-200 hover:text-purple-700 focus:text-cyan-700 hover:text-cyan-600">Forgot password?</a>
                        </div>
                    </div>
                    <div className="relative inline-flex items-center justify-center gap-3 group mt-10 w-full max-w-md">
                        <div className="absolute inset-0 duration-1000 opacity-60 transition-all bg-gradient-to-r from-indigo-500 via-pink-500 to-yellow-400 rounded-full blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>
                        <a href="/dashboard"
                            className="group relative flex items-center justify-center text-lg rounded-full bg-slate-950 px-8 py-2 font-semibold text-white transition-all duration-200 hover:bg-slate-950 hover:shadow-lg hover:-translate-y-0.5 hover:shadow-gray-600/30 w-full">
                            Sign in
                        </a>
                    </div>
                </form>
                <a href="/" className=" font-semibold text-1xl text-purple-700 mt-6 hover:underline">Back to homepage</a>
            </div>
        </div>


    );
};

export default Login;
