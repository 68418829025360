import React, { useEffect, useState } from 'react';

const ThemeSwitcher = () => {
    const [iconClassName, setIconClassName] = useState('pi-moon');

    useEffect(() => {
        // Get the theme from localStorage and apply it
        const root = document.getElementsByTagName('html')[0];
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            root.classList.toggle('dark', savedTheme === 'dark');
            setIconClassName(savedTheme === 'dark' ? 'pi-sun' : 'pi-moon');
        }
    }, []);

    const onThemeToggler = () => {
        const root = document.getElementsByTagName('html')[0];
        const newTheme = root.classList.toggle('dark') ? 'dark' : 'light';

        // Save the new theme to localStorage
        localStorage.setItem('theme', newTheme);
        setIconClassName((prevClassName) =>
            prevClassName === 'pi-moon' ? 'pi-sun' : 'pi-moon'
        );
    };

    return (
        <div>
            <button
                type="button"
                className="flex border-1 w-2rem h-2rem p-0 align-center justify-center"
                onClick={onThemeToggler}
            >
                <i className={`dark:text-white pi ${iconClassName}`} />
            </button>
        </div>
    );
};

export default ThemeSwitcher;
