

// // src/App.js
// import React from 'react';
// import './App.css';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Removed Switch since Routes is used
// import HomePage from './pages/Homepage'; // Ensure the path is correct for your HomePage component
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Register from './pages/RegisterPage'; // Register page component
// import Login from './pages/LoginPage';
// // Import NotFoundPage
// import NotFound from './pages/404';
// import DashboardLayout from './components/DashboardLayout';

// function App() {
//   return (
//     <Router>
//       <div className="App flex flex-col min-h-screen">

//         <main className="flex-grow">

//           <Routes>
//             <Header />
//             <Route path="/" element={<HomePage />} /> {/* Home page route */}
//             <Route path="/signup" element={<Register />} /> {/* Register page route */}
//             <Route path="/login" element={<Login />} /> {/* Login page route */}

//             <Route path="*" element={<NotFound />} /> {/* 404 Page route */}
//           </Routes>
//           <Footer />
//           <Routes>
//             <Route path="*" element={<NotFound />} />
//             <Route path="/dashboard" element={<DashboardLayout />} />
//             {/* <Route path="/dashboard/courses" element={<DashboardLayout><CoursesPage /></DashboardLayout>} /> */}
//           </Routes>
//         </main>

//       </div>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Homepage'; // Public HomePage
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './pages/RegisterPage'; // Register page component
import Login from './pages/LoginPage'; // Login page component
import NotFound from './pages/404'; // 404 Page


function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">

        {/* Routes for Public Pages (with Header and Footer) */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <HomePage />
                <Footer />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Header />
                <Register />
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <Login />
                <Footer />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Header />
                <NotFound />
                <Footer />
              </>
            }
          />
          {/* <Route
            path="/dashboard"
            element={
              <>

                <Dashboard />

              </>
            }
          /> */}
        </Routes>

        {/* Routes for Dashboard Pages (without Header and Footer) */}





      </div>
    </Router>
  );
}

export default App;

